<template>
  <Modal v-model="selectPublishStoreModal" :title="$t('key1000500')" width="550" :transfer="false" :mask-closable="false"
    @on-visible-change="selectPublishStoreChange">
    <Form ref="publishStoreForm" :model="selectPublishStoreForm" :label-width="100" :rules="ruleValidate" @submit.native.prevent>
      <Row>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" v-if="hideSite">
          <Form-item :label="$t('key1000501')" prop="siteIds">
            <Select v-model="selectPublishStoreForm.siteIds" filterable transfer multiple :placeholder="$t('key1000095')"
              @on-change="changeSite" style="width: 300px;">
              <Option v-for="(item, index) in siteList" :value="item.siteId" :key="index">{{ item.siteName }}</Option>
            </Select>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1000502')" prop="ymsPlatformAccountId">
            <Select v-model="selectPublishStoreForm.ymsPlatformAccountId" filterable transfer @on-change="changeStore" style="width: 300px;">
              <Option v-for="(item, index) in publishStoreList" :value="item.saleAccountId" :key="index">{{ item.accountCode }}</Option>
            </Select>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1000503')" prop="publishTemplateId">
            <div class="flex align-items">
              <Select v-model="selectPublishStoreForm.publishTemplateId" filterable transfer style="width: 300px;">
                <Option v-for="(item, index) in listingsTemplateList" :value="item.templateId" :key="index">{{ item.templateName }}</Option>
              </Select>
              <span class="distributorThemeColor cursor ml8" @click="goToListingPage('/yms/listing/template/index.htm')">{{ $t('key1000504') }}</span>
            </div>
          </Form-item>
        </Col>
      </Row>
    </Form>
    <template #footer>
      <Button @click="selectPublishStoreModal = false">{{ $t('key1000097') }}</Button>
      <Button type="primary" @click="publishStoreBtn">{{ $t('key1000096') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from "@/components/mixin/common_mixin";
import {commonSessionStorage, getCommonTicket} from "@/utils/common";
import {NOSITEPLATFORMLIST} from "@/utils/enum";

export default {
  name: "selectPublishStoreModal",
  mixins: [Mixin],
  data() {
    return {
      selectPublishStoreModal: false,
      selectPublishStoreForm: {
        siteIds: [], // 站点
        ymsPlatformAccountId: null, // 刊登店铺
        publishTemplateId: null, // 刊登模板
      },
      ruleValidate: {
        ymsPlatformAccountId: [
          {required: true, message: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000505'), trigger: 'change'}
        ],
        publishTemplateId: [
          {required: true, message: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000506'), trigger: 'change'}
        ]
      },
      siteList: [],
      publishStoreList: [],
      listingsTemplateList: [],
      platformId: null,
      ymsProductId: null,
      type: null
    }
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
    // 隐藏对应的平台没有站点
    hideSite() {
      let talg = true;
      if (NOSITEPLATFORMLIST.includes(this.platformId)) {
        talg = false;
      }
      return talg;
    }
  },
  methods: {
    // 初始化数据
    initSelectPublishStoreData(data, ymsProductId, type) {
      this.platformId = data.itemObj.platformId;
      this.ymsProductId = ymsProductId;
      this.type = type;
      this.publishStoreList = [];
      let list = data.itemObj.siteResultBos || [];
      this.siteList = this.uniqueFunc(list, 'siteId');
      let storeData = data.storeData || [];
      if (storeData.length > 0) {
        storeData.map((item) => {
          let accountInfoList = item.accountInfoList || [];
          if (accountInfoList.length > 0) {
            this.publishStoreList.push(...accountInfoList);
          }
        })
      } else {
        this.getPublishStoreData();
      }
      this.selectPublishStoreModal = true;
    },
    // 获取对应平台的店铺数据
    getPublishStoreData() {
      let v = this;
      let query = {
        platformId: v.platformId,
        status: 1 // 1'‑启用 '0'‑停用
      };
      v.axios.post(api.post_ymsDistributorProductInfo_querySaleAccountInfoListByPlatform, query).then(response => {
        v.tableLoading = false;
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          if (data.length > 0) {
            data.map((item) => {
              let accountInfoList = item.accountInfoList || [];
              if (accountInfoList.length > 0) {
                v.publishStoreList.push(...accountInfoList);
              }
            })
          }
        }
      });
    },
    // 选择站点
    changeSite(val) {
      this.selectPublishStoreForm.publishTemplateId = null;
      this.listingsTemplateList = [];
      if (val) {
        this.getListingsTemplateData();
      }
    },
    // 选择刊登店铺
    changeStore(val) {
      this.selectPublishStoreForm.publishTemplateId = null;
      this.listingsTemplateList = [];
      if (val) {
        this.getListingsTemplateData();
      }
    },
    // 获取对应平台刊登的模版数据
    getListingsTemplateData() {
      let v = this;
      let query = {
        platformId: v.platformId,
        saleAccountId: v.selectPublishStoreForm.ymsPlatformAccountId,
        siteIds: v.selectPublishStoreForm.siteIds
      };
      v.listingsTemplateList = [];
      v.axios.post(api.post_ymsDistributorProductInfo_queryTemplateByPlatform, query).then(response => {
        v.tableLoading = false;
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          if (data.length > 0) {
            data.map((item) => {
              let templateInfoList = item.templateInfoList || [];
              if (templateInfoList.length > 0) {
                v.listingsTemplateList.push(...templateInfoList);
              }
            })
          }
        }
      });
    },

    // 确定
    publishStoreBtn() {
      let v = this;
      let query = {
        distributorMerchantId: v.userInfo.merchantId,
        ymsProductIds: [v.ymsProductId],
        type: v.type,
        platformIds: [v.platformId],
        siteIds: v.selectPublishStoreForm.siteIds,
        ymsPlatformAccountId: v.selectPublishStoreForm.ymsPlatformAccountId, // 刊登店铺
        publishTemplateId: v.selectPublishStoreForm.publishTemplateId, // 刊登模板
      }
      v.$refs['publishStoreForm'].validate((valid) => {
        if (valid) {
          v.axios.post(api.post_ymsDistributorProductInfo_draft, query, {
            loading: true,
            loadingText: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000212')
          }).then(response => {
            if (response.data.code === 0) {
              v.selectPublishStoreModal = false;
              let data = response.data.datas;
              if (data) {
                let link = data.url;
                let status = data.status; // 1 成功 -1 失败
                if (status === 1) {
                  let obj = commonSessionStorage.getItem('erpConfig');
                  let merchant = Object.keys(obj).length > 0 ? obj.merchant : null;
                  let url = merchant.listingStatus === '1' ? merchant.listingDomain : merchant.ymsListingDomain;
                  if (url) {
                    let path = url.includes('http') ? url : 'https://' + url;
                    let iframeUrl = path + '/j_security_check?ticket=';
                    if (iframeUrl) {
                      let query = {
                        userId: v.userInfo.userId,
                        merchantId: v.userInfo.merchantId
                      }
                      getCommonTicket(query).then((ticket) => {
                        if (ticket) {
                          v.openNewWindowWithIframe(iframeUrl + ticket, link)
                        }
                      });
                    }
                  }
                } else {
                  v.$Message.error(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000507'));
                  return false;
                }
              }
            }
          });
        }
      });
    },
    // 处理跳转
    openNewWindowWithIframe(iframeSrc, link) {
      let newWindow = window.open(iframeSrc, '_blank');
      if (newWindow) {
        setTimeout(() => {
          window.open(link, '_blank');
          newWindow.close();
        }, 1500);
      }
    },
    // 监听弹窗
    selectPublishStoreChange(value) {
      if (!value) {
        this.removeStopScroll();
        this.platformId = null;
        this.ymsProductId = null;
        this.type = null;
        this.$refs['publishStoreForm'].resetFields();
      } else {
        this.stopScroll();
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ivu-form-item-label {
  padding-right: 0 !important;
}
</style>