<template>
  <Modal v-model="publishingPlatformModal" footer-hide width="728" :title="$t('key1000894')" @on-visible-change="publishingPlatformChange">
    <div class="publishingPlatform_box">
      <div class="publishingPlatform_box_item" v-for="item in publishingPlatformList" @click="selectPublishingPlatform(item)">
        <img :src="item.logoPath" width="107" height="42" alt="" :onerror="errorPlaceholder">
        <span class="mt8">{{ item.platformName === 'ebay' ? item.platformId : item.platformName }}</span>
      </div>
    </div>
  </Modal>
</template>

<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from "@/components/mixin/common_mixin";
import {pageJump} from "@/utils/common";

export default {
  name: "publishingPlatformModal",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      publishingPlatformModal: false,
      publishingPlatformList: [],
      errorPlaceholder: "this.onerror='';this.classList.add('error-image'); this.src='" + require('@/assets/images/imageError.jpg') + "'",
      aliexpressChoiceLogoPath: require('@/assets/images/platform/aliexpressChoice.png'),
      temuChoiceLogoPath: require('@/assets/images/platform/temu_choice.png')
    }
  },
  methods: {
    // 初始化数据
    initPublishingPlatformData(data, ymsProductCategoryId, source) {
      let v = this;
      if (data && data.length > 0) {
        let newList = [];
        let newData = [];
        if (source === 'listing') {
          data.map((item) => {
            let obj = {
              platformId: item,
              platformName: item !== 'eBay' ? item.charAt(0).toUpperCase() + item.slice(1) : item
            }
            newData.push(obj)
          })
        } else {
          newData = JSON.parse(JSON.stringify(data));
        }
        v.getEnablePlatform([], ymsProductCategoryId).then((list) => {
          newData.map((ele) => {
            // 速卖通全托管平台
            if (ele.platformId === 'aliexpress') {
              let obj = {
                platformName: 'AliexpressChoice',
                platformId: 'aliexpressChoice',
                logoPath: v.aliexpressChoiceLogoPath,
              };
              newList.push(obj);
            }
            list.map((item) => {
              if (source === 'listing' && ele.platformId === item.platformId) {
                ele.platformName = item.name;
              }
              if (ele.platformId === item.platformId || ele.platformName === item.name || ele.platformName === item.platformId) {
                // Temu半托管平台
                if (ele.platformId === 'temu_choice') {
                  ele.logoPath = v.temuChoiceLogoPath;
                } else {
                  ele.logoPath = item.logoPath;
                }
              }
            });
          });
          v.publishingPlatformList = [...newData, ...newList];
          if (v.publishingPlatformList.length > 0) {
            v.publishingPlatformModal = true;
          } else {
            v.$Message.warning(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000895'));
            v.publishingPlatformModal = false;
            return false;
          }
        });
      } else {
        v.$Message.warning(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000896'));
        return false;
      }
      v.publishingPlatformModal = true;
    },
    // 选择刊登的平台
    selectPublishingPlatform(item) {
      // 判断当前平台是否有绑定店铺
      let v = this;
      let query = {
        platformId: item.platformId,
        status: 1 // 1'‑启用 '0'‑停用
      };
      v.axios.post(api.post_ymsDistributorProductInfo_querySaleAccountInfoListByPlatform, query).then(response => {
        v.tableLoading = false;
        if (response.data.code === 0) {
          let data = response.data.datas || [];
          if (data.length > 0) {
            v.$emit('changePlatform', {itemObj: item, storeData: data});
          } else {
            v.$Modal.warning({
              title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000897'),
              content: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000898'),
              okText: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000899'),
              onOk: () => {
                pageJump('/distributor.html#/platformStoreList', '/yms-distributor-service', '_blank');
              }
            });
          }
          v.publishingPlatformModal = false;
        }
      });
    },
    // 监听弹窗
    publishingPlatformChange(value) {
      if (value) {
        this.stopScroll();
      } else {
        this.removeStopScroll();
      }
    }
  }
}
</script>

<style lang="less" scoped>
.publishingPlatform_box {
  display: flex;
  flex-wrap: wrap;
  max-height: 600px;

  .publishingPlatform_box_item {
    width: 107px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 16px 16px;
    cursor: pointer;

    img {
      border: 1px solid #E1E1E1;
      box-sizing: border-box;
      border-radius: 5px;
      overflow: hidden;
    }
  }
}
</style>